<template>
<div id="page-catalogos-sat">
    <div>
        <v-breadcrumbs :items="breadcrumbs">
            <v-icon slot="divider">forward</v-icon>
        </v-breadcrumbs>
    </div>
    <v-container fluid fill-height>

        <v-tabs grow style="margin-bottom: 30px;">
            <v-tabs-slider color="primary" ></v-tabs-slider>

            <v-tab key="productos_servicios">
                <v-card-title><b>Productos y Servicios</b></v-card-title>
            </v-tab>
            <v-tab key="unidades">
                <v-card-title><b>Unidades de Medida</b></v-card-title>
            </v-tab>
            <v-tab key="usoscfdi">
                <v-card-title><b>Usos CFDI</b></v-card-title>
            </v-tab>
            <v-tab key="formaspago">
                <v-card-title><b>Formas de Pago</b></v-card-title>
            </v-tab>
            <v-tab key="metodospago">
                <v-card-title><b>Métodos de Pago</b></v-card-title>
            </v-tab>
            <v-tab key="regimenes">
                <v-card-title><b>Regímenes Fiscales</b></v-card-title>
            </v-tab>

            <v-tab-item key="productos_servicios" >
                <productoservicios ref="productoservicios" ></productoservicios>
            </v-tab-item>      

            <v-tab-item key="unidades" >
                <unidadesmedida ref="unidadesmedida" ></unidadesmedida>
            </v-tab-item>      

            <v-tab-item key="usoscfdi" >
                <usoscfdi ref="usoscfdi" ></usoscfdi>
            </v-tab-item>      
            
            <v-tab-item key="formaspago" >
                <formaspago ref="formaspago" ></formaspago>
            </v-tab-item>

            <v-tab-item key="metodospago" >
                <metodospago ref="metodospago" ></metodospago>
            </v-tab-item>

            <v-tab-item key="regimenes" >
                <regimenes ref="regimenes" ></regimenes>
            </v-tab-item>


        </v-tabs>
    </v-container>
</div>
</template>

<script>
import unidadesmedida from "@/views/catalogos/sat/UnidadesMedida";
import productoservicios from "@/views/catalogos/sat/ProductosServicios";
import usoscfdi from "@/views/catalogos/sat/UsosCFDI";
import formaspago from "@/views/catalogos/sat/FormasPago";
import metodospago from "@/views/catalogos/sat/MetodosPago";
import regimenes  from "@/views/catalogos/sat/RegimenFiscales";
export default {
     mounted: function(){
        if (!this.verificaPermiso('t.catalogos.sat')){
            this.$router.replace("AccessDenied").catch(()=>{});
        }
    },
    components: {
        unidadesmedida, productoservicios, usoscfdi, 
        formaspago, metodospago, regimenes
    },
    created: function () {        
    },
    data() {
        return {    
            breadcrumbs: [{
                    text: "Inicio",
                    disabled: false,
                    to: "/index"
                },
                {
                    text: "Catálogos",
                    disabled: true,
                    href: ""
                },
                {
                    text: "Catálogos SAT",
                    disabled: true,
                    href: ""
                }
            ],         
        };
    },
    methods: {
                 

}
};
</script>
<style scoped>
.footer-hide>>>.VuePagination {
    display: none;
}

.theme--light.v-tabs>.v-tabs-bar .v-tab:not(.v-tab--active),
.theme--light.v-tabs>.v-tabs-bar .v-tab:not(.v-tab--active)>.v-icon,
.theme--light.v-tabs>.v-tabs-bar .v-tab--disabled {
    color: black;
    font-size: small;
    background-color: #EEEEEE;
    padding: 0px;
}

.v-tabs .v-tabs-bar .v-tab.v-tab--active {
    color: white;
    font-size: small;
    background-color: #df7205;
    padding: 0px;
    font-weight: bold;

}

.v-tabs-slider-wrapper {
    left: 0 !important;
}

.table-bordered thead th,
.table-bordered thead td {
    font-size: x-small !important;
}
</style>
