<template>
<div id="page-metodos-pago">
    <v-container fluid fill-height>
        <v-row align-center justify-center>
            <v-col>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-speed-dial direction="left" open-on-hover>
                            <template v-slot:activator>
                                <v-btn x-small fab dark color="primary" >
                                    <v-icon>reorder</v-icon>
                                </v-btn>
                            </template>
                            <upload-excel-component :on-success="handleSuccess" :before-upload="beforeUpload" />
                            <exportar-excel :data="camposLayout" :exportFields="encabezadoLayout" name="Plantilla MetodosPago.xlsx" >
                                <v-btn fab dark x-small color="purple darken-2" title="Descargar Plantilla" v-tippy>
                                    <v-icon dark>receipt</v-icon>
                                </v-btn>
                            </exportar-excel>
                        </v-speed-dial>
                        <v-btn fab dark x-small color="secondary" slot="activator" @click.native="openModal(1)" title="Agregar Registro" v-tippy>
                            <v-icon dark>add</v-icon>
                        </v-btn>
                    </v-card-actions>

                    <v-card-text>
                        <div id="datagrid">

                            <v-client-table ref="vuetable" :data="registros.items" :columns="columns" :options="options" class="elevation-2 pa-2">
                                <template slot="noResults">
                                    <v-alert :value="true" icon="info" color="info">No se encontró ningún registro</v-alert>
                                </template>

                                <template slot="actions" slot-scope="props">
                                    <v-speed-dial v-model="props.row.id" direction="left" open-on-hover>
                                        <template v-slot:activator>
                                            <v-btn x-small fab dark color="primary">
                                                <v-icon v-if="fab[props.row.id]">mdi-close</v-icon>
                                                <v-icon v-else>reorder</v-icon>
                                            </v-btn>
                                        </template>
                                        <v-btn x-small fab dark color="blue" @click.native="getRegistro(props.row._id)" title="Editar Registro" v-tippy>
                                            <v-icon>edit</v-icon>
                                        </v-btn>
                                        <v-btn x-small fab dark color="pink" @click.native="deleteRegistro(props.row._id, props.row._rev )" title="Eliminar Registro" v-tippy>
                                            <v-icon>delete</v-icon>
                                        </v-btn>
                                    </v-speed-dial>
                                </template>
                            </v-client-table>
                        </div>
                    </v-card-text>
            </v-col>
        </v-row>
    </v-container>

    <v-dialog v-model="modal" max-width="30%">
        <v-card>
            <v-card-title>
                <v-card class="primary white--text titulomodal">
                    <v-icon class="white--text">{{ update ? "edit" : "add" }}</v-icon>&nbsp;
                    <span class="subheading">
                        <strong>{{ update ? "Actualizar" : "Nuevo" }} Método de Pago</strong>
                    </span>
                </v-card>
                <v-spacer></v-spacer>
                <v-btn icon @click.native="modal = false" class="close_modal">
                    <v-icon class="white--text">cancel</v-icon>
                </v-btn>
            </v-card-title>
            <v-card-text>
                <v-form ref="form" lazy-validation>
                    <v-container grid-list-md @keyup.enter="update ? updateRegistro() : saveRegistro()">
                        <v-row>
                            <v-col sm="12" md="12" lg="12">
                                <v-text-field label="Clave" v-model="model.clave" @input="model.clave=model.clave.toString().toUpperCase()" :rules="[rules.required]"
                                    ></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col sm="12" md="12" lg="12">
                                <v-text-field label="Nombre" v-model="model.nombre" :rules="[rules.required]"
                                    ></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col sm="12" md="12" lg="12">
                                <v-switch
                                    v-model="model.estatus"
                                    :label="model.estatus"
                                    true-value="Activo" false-value="Inactivo"
                                ></v-switch>
                            </v-col>
                        </v-row>

                    </v-container>
                </v-form>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn @click.native="modal = false">
                    <v-icon>cancel</v-icon> Cancelar
                </v-btn>
                <v-btn color="success" @click.native="update ? updateRegistro() : saveRegistro()">
                    <v-icon>done</v-icon> {{ update ? "Actualizar" : "Guardar" }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</div>
</template>

<script>
export default {
    created: function () {
        this.getMetodosPago();
    },
    data() {
        return {

            fab: [],
            name: "datagrid",
            columns: [
                "clave",
                "nombre",
                "estatus",
                "actions"
            ],
            show_loading: true,
            options: {
                filterable: ["clave", "nombre", "estatus"],
                sortable: ["clave","nombre", "estatus"],

                headings: {
                    clave:"Clave",
                    nombre: "Nombre",
                    estatus: "Estatus",
                    actions: "Operaciones"
                },
                listColumns: {
                    estatus: [{
                            id: 'Activo',
                            text: "Activo"
                        },
                        {
                            id: 'Inactivo',
                            text: "Inactivo"
                        }
                    ]
                },
            },

            modal: false,

            update: false,

            model: {
                _id: "",
                _rev: "",
                type:"sat_metodos_pago",
                clave:"",
                nombre: "",
                estatus: "Activo",
            },
            rules: {
                required: v => !!v || "Este campo es requerido",
                entero: function(numero){
                    const pattern = /^[\d]*$/;
                    return pattern.test(numero) || "Debe ser un número entero";
                },

            },
            search: "",
            registros: {
                selected: [],
                items: []
            },
            camposObligatorios: [
                "clave",
                "nombre",
                "estatus"
            ],
            encabezadoLayout: {
                clave: "clave",
                nombre: "nombre",
                estatus: "estatus",
            },
            //van vacíos para solo descargar layout con encabezados
            camposLayout: [{
                clave: "[Clave SAT del Método de Pago]",
                nombre: "[Nombre del Método de Pago]",
                estatus: "[Activo | Inactivo]",
            }]

        };
    },
    methods: {
        openModal: function (type) {
            if (type === 1) {
                this.update = false;
                this.model._id = "";
                this.model.nombre = "";
                this.model.clave = "";
                this.model.estatus = "Activo";
            } else {
                this.update = true;
            }

            this.modal = true;
        },

        getMetodosPago: function () {

            window.dialogLoader.show('Espere un momento por favor..');

            let data = {
                    "selector":{"type":"sat_metodos_pago"},                    
                    };

            window.axios
                .post(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_find/',data)
                .then(response => {
                    if (response.data.docs.length > 0){
                        response.data.docs.sort(this.ordenar_clave);
                        this.registros.items = response.data.docs;
                    } else
                        this.registros.items = [];
                    window.dialogLoader.hide();
                })
                .catch(error => {
                    this.$swal({
                        type: "error",
                        title: "¡Operación no Permitida!",
                        text: "Ocurrió un error al obtener los registros.",
                        footer: ""
                    });
                    window.dialogLoader.hide();
                });
        },
        ordenar_clave: function(a, b) {
            if (a.clave.toLowerCase() < b.clave.toLowerCase()) {
                return -1;
            }
            if (a.clave.toLowerCase() > b.clave.toLowerCase()) {
                return 1;
            }
            return 0;
        },

        getRegistro: function (id_registro) {

            window.axios
                .get(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE+ "/" + id_registro)
                .then(response => {
                    this.model = response.data;
                    this.openModal(2);
                })
                .catch(error => {

                    this.$swal({
                        type: "error",
                        title: "¡Operación no Permitida!",
                        text: "Ocurrió un error al obtener el registro",
                        footer: ""
                    });
                });
        },
    validaUnicos: async function(){
      var self = this;
      return new Promise(async function(resolve, reject) {
        var duplicados = [];
          await self.validaNombre().then(result => {
          }).catch(err => {
            duplicados.push("Nombre");
          });

          await self.validaClave().then(result => {
          }).catch(err => {
            duplicados.push("Clave");
          });

          if(duplicados.length > 0)
            return reject(duplicados);
          else
            return resolve(true);

      });

    },

    validaNombre: function() {
        var self = this;
        return new Promise(async function(resolve, reject) {
            if (self.model.nombre != null && self.model.nombre!="") {
                var busca = encodeURI(escape(self.model.nombre.toString().trim().toLowerCase()));
                window.axios
                .get(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_design/sat_metodos_pago/_view/nombre?key=\"'+busca+'\"')
                .then(response => {

                    if(response.data != null && response.data.rows!=null && response.data.rows.length>0){
                        if(self.model._id != ""){//Esta editando
                        var filter = response.data.rows.find( r => {
                            return self.model._id != r.id;
                        });
                        if(filter){
                            return reject(false);
                        }
                        } else { //Es nuevo
                            return reject(false);
                        }
                    }
                    return resolve(true);
                })
                .catch(err => {
                    return reject(false);
                });
            } else
                return resolve(true);
        });
        },

        validaClave: function() {
        var self = this;
        return new Promise(async function(resolve, reject) {
            if (self.model.clave != null && self.model.clave!="") {
                var busca = encodeURI(escape(self.model.clave.toString().trim().toLowerCase()));
                window.axios
                .get(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_design/sat_metodos_pago/_view/clave?key=\"'+busca+'\"')
                .then(response => {

                    if(response.data != null && response.data.rows!=null && response.data.rows.length>0){
                        if(self.model._id != ""){//Esta editando
                        var filter = response.data.rows.find( r => {
                            return self.model._id != r.id;
                        });
                        if(filter){
                            return reject(false);
                        }
                        } else { //Es nuevo
                            return reject(false);
                        }
                    }
                    return resolve(true);
                })
                .catch(err => {
                    return reject(false);
                });
            } else
                return resolve(true);
        });
        },
        saveRegistro: function () {
            if (this.$refs.form.validate()) {

                window.dialogLoader.show('Espere un momento por favor..');
                this.validaUnicos().then( result => {

                    let data = {
                        type: this.model.type,
                        clave: this.model.clave.toString().trim(),
                        nombre: this.model.nombre.toString().trim(),
                        estatus: this.model.estatus,
                    };

                    window.axios
                        .post(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/', data)
                        .then(response => {
                            this.modal = false;
                            window.dialogLoader.showSnackbar('El registro se creó correctamente..', {
                                color: 'success'
                            });
                            this.getMetodosPago();
                        })
                        .catch(error => {
                            this.$swal({
                                type: "error",
                                title: "¡Operación no Permitida!",
                                text: "Ocurrió un error al guardar el registro.",
                                footer: ""
                            });
                        }).then(() => {
                            window.dialogLoader.hide();
                        });
                }).catch( err => {

                    window.dialogLoader.hide();
                    var mensaje = "";
                    if(err.length > 1)
                        mensaje = err.join(', ')+" ya existen. Favor de verificar.";
                    else
                        mensaje = err.join(', ')+" ya existe. Favor de verificar.";

                    this.$swal({
                        type: "error",
                        title: "¡Operación no Permitida!",
                        text: mensaje,
                        footer: ""
                    });
                });
            }
        },
        updateRegistro: function () {

            if (this.$refs.form.validate()) {
                window.dialogLoader.show('Espere un momento por favor..');
                this.validaUnicos().then( result => {
                    let data = this.model;
                    window.axios
                        .put(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/' + this.model._id + '?conflicts=true', data)
                        .then(response => {
                            window.dialogLoader.showSnackbar('El registro se actualizó correctamente..', {
                                color: 'success'
                            });
                            this.getMetodosPago();
                        })
                        .catch(error => {
                            window.dialogLoader.showSnackbar('Ocurrió un error al actualizar el registro..', {
                                color: 'error'
                            });
                        }).then(() => {
                            this.modal = false;
                            window.dialogLoader.hide();
                        });
                }).catch( err => {

                    window.dialogLoader.hide();
                    var mensaje = "";
                    if(err.length > 1)
                        mensaje = err.join(', ')+" ya existen. Favor de verificar.";
                    else
                        mensaje = err.join(', ')+" ya existe. Favor de verificar.";

                    this.$swal({
                        type: "error",
                        title: "¡Operación no Permitida!",
                        text: mensaje,
                        footer: ""
                    });
                });
            }
        },
        deleteRegistro: function (_id, _rev) {
            this.$swal({
                type: "info",
                title: "Cuidado",
                text: "¿Está seguro de eliminar el registro?",
                footer: "",
                showCancelButton: true,
                cancelButtonColor: "#d33",
                confirmButtonColor: "#3085d6",
                confirmButtonText: "Aceptar",
                cancelButtonText: "Cancelar"
            }).then(result => {
                if (result.value) {
                    window.dialogLoader.show('Espere un momento por favor..');
                    window.axios
                        .delete(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE+ "/" + _id + "?rev=" + _rev)
                        .then(response => {
                            window.dialogLoader.showSnackbar('El registro se eliminó correctamente..', {
                                color: 'success'
                            });
                            this.getMetodosPago();
                        })
                        .catch(error => {
                            window.dialogLoader.showSnackbar('Ocurrió un error al eliminar el registro..', {
                                color: 'error'
                            });
                        }).then(()=>{
                            window.dialogLoader.hide();
                        });

                }

            });
        },

        beforeUpload(file) {
            const isLt2M = file.size / 1024 / 1024 < 2;

            if (isLt2M) {
                return true;
            }
            this.$swal({
                type: "warning",
                title: "¡Operación no Permitida!",
                text: "El archivo no debe ser mayor a 2MB.",
                footer: ""
            });
            return false;
        },
        buscaClave: function(val){
            var filter = [];
            var filter = this.registros.items.find( e => e.clave.toString().trim()==val.toString().trim());

            if(filter){
                return {"_id":filter._id, "_rev":filter._rev};
            } else
                return false;
        },
        soloNumeros: function(value){
            const pattern = /^[\d]*$/;
            if (value!=null && !pattern.test(value) ){
                return false;
            }
            else
                return true;

        },
        verificaObligatorios: function (results) {
            var errores = '';
            var keys = [];
            for (var k in results) {
                keys.push(k);
            }
            this.camposObligatorios.forEach(function (campo) {
                if (keys.indexOf(campo) == -1)
                    errores += '<br/>' + "- El campo '" + campo + "' es obligatorio.";
            });

            return errores == '' ? true : errores;
        },
        validaCargaLayout: async function (results) {
            var thisObject = this;
            //return new Promise(function(resolve, reject){
            var errores = 0;
            var erroresText = '';
            var errorTmp = '';
            var claves_unicas = [];
            var camposPermitidos = this.camposObligatorios;
            try {
                if (results.length == 0) {
                    errores++;
                    erroresText += "No hay datos en la plantilla. Verifique que exista información a partir del 3er renglón.";
                } else {
                    for (var k in results) {
                        //results[k]["user_updated"] = this.$local_storage.get("email");

                        for(var key in results[k]){//Eliminamos campos extras que no se deben guardar
                            if(!camposPermitidos.includes(key)){
                                delete(results[k][key]);
                            }
                        }

                        var obligatorios = this.verificaObligatorios(results[k]);
                        if (obligatorios !== true) {
                            errores++;
                            if (erroresText.indexOf(obligatorios) == -1)
                                erroresText += obligatorios;
                        } else {
                            results[k]["clave"] = results[k]["clave"].toString().trim().toUpperCase();
                            if(claves_unicas.includes(results[k]["clave"])){
                                errores++;
                                errorTmp = "<br/>- La clave "+results[k]["clave"]+" esta repetida en la plantilla";
                                if (erroresText.indexOf(errorTmp) == -1)
                                    erroresText += errorTmp;
                            } else {
                                claves_unicas.push(results[k]["clave"]);
                            }


                            results[k]["nombre"] = results[k]["nombre"].toString().trim();
                            results[k]["estatus"] = results[k]["estatus"].toString().trim();
                            if (!["Activo","Inactivo"].includes(results[k]["estatus"])){
                                errores++;
                                errorTmp = "<br/>- El estatus solo puede ser Activo ó Inactivo";
                                if (erroresText.indexOf(errorTmp) == -1)
                                    erroresText += errorTmp;
                            }
                        }
                        //console.log("Key is " + k + ", value is" + results[k]);
                    }

                }

                return ({
                    "errores": errores,
                    "erroresText": erroresText
                });

            } catch (error) {
                console.log(error);
                thisObject.$swal({
                    type: "warning",
                    title: "¡Operación no Permitida!",
                    text: "Error al cargar el archivo. Verifique que no existan celdas vacías.",
                    footer: error.message,
                });
            }
            //}); //promise

        },
        handleSuccess: async function ({results,header}) {
            var errores = 0;
            var erroresText = '';
            try {

                window.dialogLoader.show('Espere un momento por favor..');

                let validaciones = await this.validaCargaLayout(results);
                if (validaciones.errores > 0) {
                    this.$swal({
                        type: "warning",
                        title: "¡Operación no Permitida!",
                        text: "Existen errores al cargar el archivo. Corrija y vuelva a intentarlo.",
                        footer: validaciones.erroresText,
                    });
                    window.dialogLoader.hide();
                } else {
                    for(var k in results){
                         results[k]["type"] = "sat_metodos_pago";
                        var busqueda = this.buscaClave(results[k]["clave"]);
                        if(busqueda != false){//Para que actualice las claves que ya existían
                            results[k]["_id"] = busqueda["_id"];
                            results[k]["_rev"] = busqueda["_rev"];
                        }
                    }

                   let data = {"docs":results};
                    window.axios
                    .post(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_bulk_docs', data)
                    .then(response => {
                        window.dialogLoader.showSnackbar('La plantilla se cargó correctamente..', {
                            color: 'success'
                        });
                    })
                    .catch(error => {
                        window.dialogLoader.showSnackbar('Ocurrió un error al cargar la plantilla..', {
                            color: 'error'
                        });
                    }).then(() => {
                        window.dialogLoader.hide();
                        this.getMetodosPago();
                    });
                }

            } catch (error) {
                this.$swal({
                    type: "warning",
                    title: "¡Operación no Permitida!",
                    text: "Error al cargar el archivo. Verifique que no existan celdas vacías.",
                    footer: error.message,
                });
                window.dialogLoader.hide();
            }
        }

}
};
</script>
